const CLOUDFRONT_BASE_URL =
  "https://d2v7kzglnr2dnh.cloudfront.net/images/neetodeploy";

export const LINES_WITH_GIF = [
  {
    status: "initiated",
    line: "We are giving it a wake-up jolt.",
    src: `${CLOUDFRONT_BASE_URL}/energy-energized.gif`,
    className: "max-w-full max-h-full",
  },
  {
    status: "initiated",
    line: "We are giving it a wake-up jolt of virtual expresso.",
    src: `${CLOUDFRONT_BASE_URL}/energy-energized.gif`,
    className: "max-w-full max-h-full",
  },
  {
    status: "initiated",
    line: "We are currently filling our bucket with cold water to give it a wake-up jolt.",
    src: `${CLOUDFRONT_BASE_URL}/shock.gif`,
    className: "max-w-full max-h-full",
  },
  {
    status: "initiated",
    line: "We are giving it a tickle from the motherboard.",
    src: `${CLOUDFRONT_BASE_URL}/motherboard.gif`,
    className: "max-w-full max-h-full",
  },
  {
    status: "initiated",
    line: "We are currently brewing coffee for it. You know some folks can't function without coffee.",
    src: `${CLOUDFRONT_BASE_URL}/recharge-with-coffee.gif`,
    className: "max-w-full max-h-full",
  },
  {
    status: "initiated",
    line: "We are looking for our army grade bugle horn to wake up this sleepy giant.",
    src: `${CLOUDFRONT_BASE_URL}/horn.gif`,
    className: "max-w-full max-h-full",
  },
  {
    status: "boot_up_error",
    line: "Your app has taken too long to wake up. This is not expected. Our team has been notified of this and is looking into it. Please retry after some time.",
    src: `${CLOUDFRONT_BASE_URL}/internal-server-error.gif`,
    className: "max-w-full max-h-full",
  },
  {
    status: "image_pull_error",
    line: "Sorry, we are unable to boot up your app at this moment. Our team has been notified and is looking into it.",
    src: `${CLOUDFRONT_BASE_URL}/internal-server-error.gif`,
    className: "max-w-full max-h-full",
  },
];
