import React, { useEffect, useMemo, useState } from "react";
import { Container } from "neetoui/layouts";
import { Typography } from "neetoui";
import { LINES_WITH_GIF } from "./constants";

const Sleep = ({ status, sleepTime, setAppStatus, setShowTimer }) => {
  const [counter, setCounter] = useState(sleepTime);

  // Use useMemo to ensure the random GIF is chosen only on re-render, not every render
  const randomLine = useMemo(() => {
    if (status === "initiated") {
      const randomIndex = Math.floor(
        Math.random() *
          LINES_WITH_GIF.filter((line) => line.status === "initiated").length
      );
      return LINES_WITH_GIF[randomIndex];
    } else {
      return LINES_WITH_GIF.find((line) => line.status === status);
    }
  }, [status]);

  useEffect(() => {
    if (counter > 0) {
      const timerId = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      setAppStatus("boot_up_error");
      setShowTimer(false);
    }
  }, [counter, setAppStatus]);

  return (
    <Container>
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="mx-auto flex flex-col gap-6 p-4 max-w-3xl w-full">
          <div className="w-full h-40 lg:h-60 flex items-center justify-center">
            <img
              src={randomLine.src}
              alt="Random Sleep GIF"
              className={randomLine.className}
            />
          </div>
          <div className="text-center space-y-2">
            {status === "initiated" && (
              <Typography style="body1">
                {
                  "This app has gone to sleep due to inactivity to save you money."
                }
              </Typography>
            )}
            <Typography style="body1">{randomLine.line}</Typography>
            {status === "initiated" && (
              <Typography style="body1">
                {`It would be up and running in approximately `}
                <strong>{counter}</strong>
                {` seconds.`}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Sleep;
